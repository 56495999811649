












































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { OrderMainStoragePostDto } from "@/api/appService";
import api from "@/api";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "outStorage",
})
export default class OutStorage extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  show!: boolean;

  @Prop({ required: true })
  form!: OrderMainStoragePostDto;

  formData = {} as OrderMainStoragePostDto;

  @Watch("show")
  onShowChange(newVal: boolean) {
    if (newVal) {
      Object.assign(this.formData, this.form);
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  roleRule = {
    expectedDeliveryDate: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "orderManagement.selectExpectedDeliveryDate"
        ),
        trigger: "blur",
      },
    ],
  };

  save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.orderMain
          .outStorage({
            body: this.formData,
          })
          .then(() => {
            this.$message.success(
              (this as any).$l.getLanguageText(
                "orderManagement.successfulOperation"
              ) as string
            );
            this.show = false;
            setTimeout(() => {
              (this as any).$bus.$emit("data-updated");
            }, 2000);
          })
          .catch((err) => {
            this.$message.error(err);
          });
      }
    });
  }

  cancel() {
    this.$emit("update:show", false);
  }
}
