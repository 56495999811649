



































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  OrderMainDto,
  OrderMainStoragePostDto,
  SelectListItem,
} from "@/api/appService";
import OutStorage from "@/views/productSku/orderManagement/components/outStorage.vue";

@Component({
  name: "orderManagementDetail",
  components: { OutStorage },
})
export default class orderManagementDetail extends Vue {
  detailId?: string;
  detail: OrderMainDto = {};
  loading = true;
  orderStatusList = [] as SelectListItem[];
  active = 1;
  showCloseTransactionDialog = false;
  closeTransactionForm = {
    id: undefined,
    cancelReason: undefined,
  } as any;
  showOrderDeliveryDialog = false;
  orderDeliveryForm = {
    id: undefined,
    expectedDeliveryDate: undefined,
  } as OrderMainStoragePostDto;
  orderPayMethodList = [] as SelectListItem[];
  orderReceivingMethodList = [] as SelectListItem[];

  created() {
    this.fetchOrderStatusList();
    this.fetchOrderPayMethodList();
    this.fetchOrderReceivingMethodList();
    if (this.$route.params.id) {
      this.detailId = this.$route.params.id;
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    let _this = this as any;
    await api.orderMain.get({ id: this.detailId }).then((res) => {
      _this.loading = false;
      _this.detail = { ...res };
      _this.active =
        this.orderStatusList.map((item) => item.value).indexOf(res.status) + 1;
    });
    console.log(_this.detail);
  }

  async fetchOrderStatusList() {
    let _this = this as any;
    await api.enumService.getValues({ typeName: "OrderStatus" }).then((res) => {
      this.orderStatusList = res;
      let index = this.orderStatusList
        .map((item) => item.value)
        .indexOf("Cancelled");
      _this.orderStatusList.splice(index, 1);
    });
  }

  async fetchOrderPayMethodList() {
    await api.enumService
      .getValues({ typeName: "OrderPayMethod" })
      .then((res) => {
        this.orderPayMethodList = res;
      });
  }

  async fetchOrderReceivingMethodList() {
    await api.enumService
      .getValues({ typeName: "OrderReceivingMethod" })
      .then((res) => {
        this.orderReceivingMethodList = res;
      });
  }

  getOrderReceivingMethodText(value: any) {
    let text = "";
    for (let i in this.orderReceivingMethodList) {
      if (this.orderReceivingMethodList[i].value === value) {
        text = this.orderReceivingMethodList[i].text!;
      }
    }
    return text;
  }

  getOrderPayMethodText(value: any) {
    let text = "";
    for (let i in this.orderPayMethodList) {
      if (this.orderPayMethodList[i].value === value) {
        text = this.orderPayMethodList[i].text!;
      }
    }
    return text;
  }

  cellMerge({ row, column, rowIndex, columnIndex }: any) {
    if (columnIndex === 5) {
      const _row = this.detail.detailList?.length ?? 0;
      const _col = _row > 0 ? 1 : 0;
      return {
        rowspan: _row,
        colspan: _col,
      };
    }
  }

  getSummaries(param: any) {
    //此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
    const { columns, data } = param;
    const len = columns.length;
    const sums = [] as any[];
    columns.forEach((column: any, index: any) => {
      //如果是第一列，则最后一行展示为“总计”两个字
      if (index === len - 1) {
        sums[index] = "合计：$ " + this.detail.totalAmount;
      } else {
        sums[index] = "";
      }
    });
    return sums;
  }

  handleCloseTransaction() {
    this.showCloseTransactionDialog = true;
    this.closeTransactionForm.id = this.detail.id;
  }

  async closeTransaction() {
    await api.orderMain
      .cancelled({ body: this.closeTransactionForm })
      .then(() => {
        this.$message.success(
          (this as any).$l.getLanguageText(
            "orderManagement.orderClosed"
          ) as string
        );
        this.showCloseTransactionDialog = false;
        setTimeout(() => {
          this.$router.go(0);
        }, 2000);
      })
      .catch((err) => {
        this.$message.error(err);
      });
  }

  handleOrderDelivery() {
    this.orderDeliveryForm = {
      id: this.detail.id,
    };
    this.showOrderDeliveryDialog = true;
  }

  handleCompleteDelivery() {
    this.$confirm(
      (this as any).$l.getLanguageText(
        "orderManagement.deliveryCompleted"
      ) as string,
      (this as any).$l.getLanguageText("basic.tip") as string
    ).then(async () => {
      await api.orderMain
        .received({
          body: {
            id: this.detail.id,
          },
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: (this as any).$l.getLanguageText(
              "orderManagement.successfulOperation"
            ) as string,
          });
          setTimeout(() => {
            this.$router.go(0);
          }, 2000);
        });
    });
  }

  handleInvoiceDetail() {
    this.$router.push({
      name: "invoiceDetail",
      params: { id: this.detail.id! + "" },
    });
  }
}
